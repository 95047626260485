.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card3 {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
  text-align: center;
  transition: 0.3s;
}

.card3:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card3 .icon {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 10px;
}

h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
  font-size: 0.9rem;
}

center {
  align-items: center;
  align-content: center;
}