.how-it-works-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px 0;
    margin: 0 auto;
    max-width: 1200px;
}

.card2 {
    width: 50%;
    min-width: 90%;
    background-color: #f9f9f9;
    color: #630c0c;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    overflow: auto;
}

.card2 h3 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.card2 ul {
    font-size: 14px;
    line-height: 1.5;
    padding-left: 20px;
}

.card2 h3 svg {
    margin-right: 10px;
}
