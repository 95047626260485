/* Creates a flex container that adjusts items in a row format, aligning them to the center. */
.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
}

/* Each column inside the container has an equal flex share with padding and shadow effects. */
.column {
    flex: 1;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color: #fff;
    margin: 10px;
}

/* Heading styling for better visual appearance. */
h1 {
    font-size: 2em;
    color: #630c0c;
    margin-bottom: 20px;
}

/* Paragraph styling for enhanced readability. */
p {
    font-size: 1em;
    color: #630c0c;
}

/* Style for the carousel container to hide overflow content. */
.carousel-column {
    overflow: hidden;
}

/* Media query to adjust columns for smaller screen sizes (e.g., mobile devices). */
@media (max-width: 768px) {
    .container {
        flex-direction: column-reverse; /* Stacks carousel below the text column. */
    }
}
