
.navbar {
  border-bottom: 1px solid #00fd26;
}

.navbar-nav.ml-auto {
  justify-content: flex-end !important; 
}

.nav-link .react-icons {
  margin-right: 8px;
  color: #fff !important;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #61dafb !important;
}

.background {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.box {
  background: rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Shadow effect */
  color: #630c0c;
  overflow-y: auto;
  margin: 50px;
}

.login-box {
  width: 500px;
}

.privacy-box {
  width: 95%;
}

.contactus-box {
  width: 60%;
}

.home-box {
  width: 95%;
}

.how-it-works-section {
  display: flex;
  flex-direction: column; /* ensures children are stacked vertically */
  align-items: center; /* centers the cards horizontally */
  gap: 20px; /* space between cards, can adjust as per your preference */
  padding: 20px; /* space around the entire section */
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd; /* optional: adds a border around each card */
  border-radius: 5px; /* optional: rounds the corners of the card */
  width: 95%; /* adjust as needed */
}

